<template>
  <v-content
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-img
      src="@/assets/background-eqtl-2.png"
      max-height="100vh"
    >
      <v-responsive
        min-height="100vh"
        class="d-flex align-center"
      >
        <router-view />
      </v-responsive>
    </v-img>
  </v-content>
</template>

<script>
export default {
  name: 'PagesCoreView'
};
</script>
